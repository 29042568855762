import React from 'react'
import ConsultingTemplate from 'components/templates/ConsultingTemplate/ConsultingTemplate'

export default function ConsultingUni10891Page() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'UNI 10891'

  const description =
    '<b>ING GROUP affianca gli ISTITUTI DI VIGILANZA per tutti i requisiti richiesti da D.M. 269/2010 – D.M. 115/2014 – REGOLAMENTO ATTUATIVO DM 115.2014 DEL CAPO DELLA POLIZIA 2015!</b><br> Oggi ING GROUP è la principale struttura in ITALIA capace di assicurare agli Istituti di Vigilanza quanto richiesto dal Ministero dell’Interno.<br> Abbiamo portato alla certificazione secondo la UNI 10891 i principali Istituti di Vigilanza italiani sin dal 2005.<br> Disponiamo di internal SENIOR SECURITY MANAGER certificato in conformità alla UNI 10459:2017 da ICIM Spa.<br> ING GROUP segue i principali Istituti in Italia:<br> UNI 10891: “Servizi – istituti di vigilanza privata – Requisiti”: obbligatoria (DM 269/10) per gli Isituti di Vigilanza da settembre 2012. Obbligo ribadito sotto accreditamento dal 03 settembre 2015.<br> EN 50518: Requisiti delle CENTRALI OPERATIVE: obbligatoria (DM 269/10) a partire da settembre 2012. Obbligo ribadito sotto accreditamento dal 03 settembre 2015<br> UNI 10459: Requisiti del SECURITY MANAGER: obbligatoria (DM 269/10) a partire da settembre 2012. Obbligo ribadito sotto accreditamento dal 03 settembre 2015<br> CERTIFICAZIONE REQUISITI 269/2010: Da consegnare alla Prefettura competente dal 03 settembre 2015'

  return (
    <>
      <ConsultingTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
